

.anim-highlight > span {
  transition: all 500ms ease-in-out;
}

.anim-highlight > .highlight {
  background-color: rgb(255, 238, 151);
}

.division {
  margin-bottom: 10px;
}

strong,
.text-char-simple,
.text-bible {
  font-weight: bold;
}

.text-quote {
  font-style: italic;
}

.text-bible {
  border: 1px dotted #ccc;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  color: #ccc;
}
.text-bible:hover {
  color: #666;
  border-color: #666;
}

.text-bible:hover {
  background: rgb(255, 238, 151);
}

.chapter {
  background: $main-white;
  font-size: 1.5rem;
  z-index: 2;
  position: sticky;
  top: 0px;

  margin-left: -$content-padding + px;
  margin-right: -$content-padding + px;
  padding-bottom: 8px;
}

.chapter-inner {
  position: relative;
  background: $main-white;
  width: 100%;
  box-shadow: 0 8px 5px -5px rgba(0,0,0,0.1);
  padding-left: $content-padding + px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.chapter-inner select {
  border-radius: 4px;
  border: 2px solid transparent;
  margin-left: -10px;
  padding-left: 30px;
  padding-right: 5px;
}

.chapter-inner select:hover {
  border-color: #ddd;
}

.chapter-inner .caret {
  position: relative;
}

.chapter-inner .caret span {
  position: absolute;
  left: 0px;
}

@media only screen and (max-width: 800px) {
  .wrapper {
    width: calc(100% - 40px);
  }
  .offset-on-desktop {
    right: 0px;
  }
}

.poem {
  margin-left: 20px;
  font-style: italic;
  margin-top: 10px;
}

.book-block {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: justify;
  position: relative;
}

.main-break {
  margin-bottom: 5px;
}

.hover-bookmark {
  display: none;
  position: absolute;
  top: -8px;
  left: 0px;
  background: $main-red;
  height: 4px;
  width: 100%;
  border-radius: 4px;
}


@media only screen and (max-width: 800px) {
  .book-block:hover .hover-bookmark {
    display: block;
  }
}

.book-block.bookmark .hover-bookmark {
  position: relative;
  display: block;
  height: 40px;
  border: 4px solid $main-red-hover;
  top: 0px;
  left: 25%;
  width: 50%;
  color: $main-white;
  text-align: center;
  font-size: 2rem;
  line-height: 1.5rem;
  font-weight: bold;
  text-shadow: 0 2px 2px rgba(0,0,0,0.1);
  margin: 12px 0 12px 0;
  box-shadow: 0 2px 3px rgba(0,0,0,0.25);
}

.book-block.bookmark .hover-bookmark:before {
  content: '~';
  position: absolute;
  left: 50%;
  margin-left: -5px;
}