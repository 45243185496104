

// decorative border

$decoration-w: 30;
$decoration-border-w: 3;

$decoration-full-w: $decoration-w + $decoration-border-w * 2;

.decoration-surround {
  position: absolute;
  top: ($decoration-w / 2) + px;
  width: $decoration-w + ($decoration-border-w * 2) + px;
  border: $decoration-border-w + px solid black;
  opacity: 0;
  transition: opacity 1s linear;
  border-radius: 8px;
}

.decoration.mid {
  width: $decoration-w + px;
  top: 0px;
  bottom: 0px;
  background-image: url('../assets/mid-v.png');
  background-size: $decoration-w + px $decoration-w + px;
  background-repeat: repeat-y;
}

.decoration.pre {
  background-image: url('../assets/end.png');
  background-size: $decoration-w + px $decoration-w + px;
  width: $decoration-w + px;
  height: $decoration-w + px;
}

.decoration.post {
  background-image: url('../assets/end.png');
  background-size: $decoration-w + px $decoration-w + px;
  width: $decoration-w + px;
  height: $decoration-w + px;
  transform: rotate(180deg);
}

.decoration-surround.left {
  left: -$decoration-full-w - 10 + px;
}

.decoration-surround.right {
  right: -$decoration-full-w - 10 + px;
}
