


input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

.big-input {
  width: 100%;
  font-size: 2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.05) inset;
  padding: 8px;
}

.big-input:focus {
  outline: 2px solid #333;
}

.search-result {
  cursor: pointer;
  padding: 5px;
  border-radius: 2px;
}

.search-result:hover {
  background: rgba(0,0,0,0.05);
}