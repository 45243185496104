@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import "./reset.css";

@font-face {
  font-family: "EnglishTowne";
  src: url("./assets/EnglishTowne.ttf") format("truetype");
}

$main-black: #333;
$main-white: #fffef7;
$main-bg: #f4d7ac;
$main-red: rgb(160, 43, 43);
$main-red-hover: rgb(112, 32, 32);

$content-padding: 15;

@import "./styles/base.scss";
@import "./styles/border.scss";
@import "./styles/font.scss";
@import "./styles/tooltip.scss";
@import "./styles/sparkline.scss";
@import "./styles/book.scss";
@import "./styles/search.scss";
