

.sparkline-index {
  display: flex;
  font-size: 2rem;
  align-items: end;
  margin-bottom: 10px;
}

.sparkline-index .part-1 {
  flex: 1;
  line-height: 1.7rem;
}

.sparkline-index .part-2 {
  flex: 1;
}


@media only screen and (max-width: 800px) {
  .decorative-font.part-1 {
    font-size: 1.5rem;
  }
}
