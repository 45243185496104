html,
body {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  color: $main-black;
}

body {
  background: $main-bg;
//  overflow-x: hidden;
  font-family: "Cormorant Garamond", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
}

.wrapper {
  width: calc(100% - 200px);
  margin: auto;
  position: relative;
}

.main-menu {
  position: absolute;
  padding: 10px 10px 7px 9px;
  background: $main-red;
  box-shadow: 0 2px 5px rgba(0,0,0,0.25);
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  color: white;
}

.main-menu:not(.open):hover {
  background: $main-red-hover;
}

.main-menu a {
  text-decoration: none;
  border-radius: 2px;
  padding: 4px;
}

.main-menu .subtitle {
  padding: 4px;
  opacity: 0.5;
}

.main-menu a:hover {
  background: rgba(0,0,0,0.1);
}

.offset-on-desktop {
  right: -60px;
}

.main-content {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background: $main-white;
  padding: $content-padding + px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-radius: 4px;
}

.main-header {
  z-index: 3;
}

ul {
  padding-left: 25px;
  list-style-type: disc;
}